import React, { useImperativeHandle, useRef, useState } from 'react';
import { useInputField } from '../../hooks/useInputField';
import {
    StyledInputField,
    StyledInputFieldWrapper,
    StyledLabel,
    StyledAdditionalContent,
    StyledTextArea,
} from './styled';
import { StyledInvalidMessage } from '../InvalidMessage';
import { StyledHelpText } from '../HelpText';
import { InputColorTheme } from '../../types';

type TextAreaProps = React.InputHTMLAttributes<HTMLTextAreaElement>;

export type TextAreaFieldProps = Omit<TextAreaProps, 'value'> & {
    /**
     * Adds a label to the input field. This is required for accessibilty.
     */
    label?: string;

    /**
     * Add an additional help text below the input field.
     */
    helpText?: string;

    /**
     * Add an additional help text below the input field.
     */
    invalidMessage?: string;

    /**
     * Set styling to indicate input is invalid.
     * Also shows the `invalidMessage` if provided
     */
    isInvalid?: boolean;

    /**
     * This component is uncontrolled - you will need to controll the value.
     */
    value: string;

    /**
     * When active the label will move up above the actual input field
     */
    isActive?: boolean;

    /**
     * Append a string or a component to the input field
     */
    append?: React.ReactNode;

    /**
     * Simple implementation of a theme for the input field
     */
    theme?: InputColorTheme;

    textAreaMinHeight?: number;
};

export const TextAreaField = React.forwardRef<HTMLTextAreaElement, TextAreaFieldProps>(
    (
        {
            label,
            helpText,
            invalidMessage,
            id,
            children,
            isActive,
            isInvalid,
            append,
            theme = 'grey',
            textAreaMinHeight,
            ...rest
        },
        ref,
    ) => {
        const [isValidityValid, setIsValidityValid] = useState(true);
        const [hasFocus, setHasFocus] = useState(false);
        const textAreaRef = useRef<HTMLTextAreaElement>(null);
        const textAreaFieldRef = useRef<HTMLDivElement>(null);

        const {
            fieldId,
            labelId,
            helpTextId,
            invalidMessageId,
            describedById,
            showHelpText,
            showInvalidMessage,
        } = useInputField({
            id,
            helpText,
            isInvalid,
            invalidMessage,
        });

        const { value, defaultValue } = rest;

        const hasValue = value === undefined ? !!defaultValue : !!value;
        const isInputActive = isActive || hasFocus || hasValue;
        const isValid = !showInvalidMessage && isValidityValid;

        const onFocusHandler = () => setHasFocus(true);
        const onBlueHandler = () => {
            setHasFocus(false);
            setIsValidityValid(textAreaRef.current?.validity.valid ? true : false);
        };

        const onInputFieldClick = (event: React.MouseEvent) => {
            if (textAreaRef.current !== event.target) {
                textAreaRef.current?.focus();
                textAreaRef.current?.click();
                event?.preventDefault();
                event?.stopPropagation();
            }
        };

        useImperativeHandle(ref, () => textAreaRef.current as HTMLTextAreaElement, [ref]);

        return (
            <div>
                <StyledInputFieldWrapper
                    key={fieldId}
                    onFocus={onFocusHandler}
                    onBlur={onBlueHandler}
                    isValid={isValid}
                >
                    <StyledInputField
                        onMouseDown={onInputFieldClick}
                        ref={textAreaFieldRef}
                        colorTheme={theme}
                    >
                        {label ? (
                            <StyledLabel
                                id={labelId}
                                htmlFor={fieldId}
                                title={label}
                                isActive={isInputActive}
                                isValid={isValid}
                                hasFocus={hasFocus}
                            >
                                {/* required fields always have an invalidMessage with an empty string */}
                                {`${label}${invalidMessage !== undefined ? ' *' : ''}`}
                            </StyledLabel>
                        ) : null}

                        <StyledTextArea
                            withLabel={!!label}
                            isActive={isInputActive}
                            id={fieldId}
                            aria-describedby={describedById}
                            ref={textAreaRef}
                            minHeight={textAreaMinHeight}
                            {...rest}
                        />

                        {append ? <StyledAdditionalContent children={append} /> : null}
                    </StyledInputField>
                    {children}
                </StyledInputFieldWrapper>
                {showInvalidMessage ? (
                    <StyledInvalidMessage id={invalidMessageId} children={invalidMessage} />
                ) : null}
                {showHelpText ? <StyledHelpText id={helpTextId} children={helpText} /> : null}
            </div>
        );
    },
);
