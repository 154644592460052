import { Link } from '~/lib/data-contract';
import { Text, RawHtml, CallToAction } from '$shared/components';
type FormReceiptProps = {
    headline?: string;
    description?: string;
    callToAction?: Link;
};

export const FormReceipt = ({ headline, description, callToAction }: FormReceiptProps) => {
    return (
        <div>
            {headline && (
                <Text variant="display3" as="h3">
                    {headline}
                </Text>
            )}
            {description && <RawHtml html={description} />}
            {callToAction && <CallToAction callToAction={callToAction} />}
        </div>
    );
};
