import { Text, RawHtml } from '$shared/components';
type FormErrorProps = {
    headline?: string;
    description?: string;
};

export const FormError = ({ headline, description }: FormErrorProps) => {
    return (
        <div>
            {headline && (
                <Text variant="display3" as="h3">
                    {headline}
                </Text>
            )}
            {description && <RawHtml html={description} />}
        </div>
    );
};
