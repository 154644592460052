import styled, { CSSObject } from '@emotion/styled';
import { ifNotProp, ifProp, switchProp } from 'styled-tools';
import { TextAreaFieldProps } from './TextArea';

type StyledInputProps = {
    withLabel: boolean;
    isActive: boolean;
    minHeight?: number;
};

type StyledLabelProps = {
    hasFocus: boolean;
    isActive: boolean;
    isValid: boolean;
};

type StyledInputFieldWrapperProps = {
    isValid: boolean;
};

export const StyledTextArea = styled.textarea<StyledInputProps>(
    ({ theme, minHeight }) =>
        ({
            ...theme.fontVariants.body,
            lineHeight: theme.lineHeights.base,
            backgroundColor: theme.colorTraits.inputBackground.grey,
            outline: 0,
            border: 'none',
            width: '100%',
            boxSizing: 'border-box',
            textAlign: theme.direction === 'rtl' ? 'right' : 'left',
            display: 'block',
            padding: `${theme.spaces[3]} ${theme.spaces[6]}`,
            minHeight: minHeight || 180,
            '&::placeholder': {
                transition: '0.1s color',
                color: theme.colors.grey40,
            },
        }) as CSSObject,
    ifProp('withLabel', ({ theme }) => ({
        padding: `2.5rem ${theme.spaces[6]} 0.8rem ${theme.spaces[6]}`,
    })),
    ifProp(
        { withLabel: true, isActive: false },
        {
            [`&::placeholder`]: {
                color: 'transparent',
            },
        },
    ),
);

export const StyledInputField = styled.div<{ colorTheme: TextAreaFieldProps['theme'] }>(
    ({ theme }) => ({
        borderRadius: theme.borderRadius.input,
        overflow: 'hidden',
        lineHeight: theme.lineHeights.single,
        position: 'relative',
        display: 'grid',
        gridAutoFlow: 'column',
        gridTemplateColumns: '1fr',
    }),
    switchProp('colorTheme', {
        light: ({ theme }) => ({
            backgroundColor: theme.colorTraits.inputBackground.light,
        }),
        grey: ({ theme }) => ({
            backgroundColor: theme.colorTraits.inputBackground.grey,
        }),
    }),
);

export const StyledLabel = styled.label<StyledLabelProps>(
    ({ theme }) =>
        ({
            ...theme.fontVariants.body,
            position: 'absolute',
            top: '27px',
            transform: 'translateY(-50%)',
            left: theme.spaces[6],
            transition: `${theme.animations.timingMedium} transform ${theme.animations.easeOutCubic}, ${theme.animations.timingMedium} width ${theme.animations.easeOutCubic}`,
            color: theme.colors.grey60,
            width: `calc(100% - ${theme.spaces[6]} - ${theme.spaces[6]})`,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            transformOrigin: theme.direction === 'rtl' ? 'right top' : 'left top',
            pointerEvents: 'none',
            zIndex: 2,
        }) as CSSObject,
    ifNotProp('isValid', ({ theme }) => ({
        color: theme.colors.negative,
    })),
    ifProp('isActive', {
        transform: 'scale(0.75) translateY(-2.9rem)',
    }),
);

export const StyledInputFieldWrapper = styled.div<StyledInputFieldWrapperProps>({
    position: 'relative',
});

export const StyledAdditionalContent = styled.span(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingRight: theme.spaces[6],
    fontSize: theme.fontSizes.xs,
    pointerEvents: 'none',

    svg: {
        overflow: 'visible',
    },
}));
